var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVersionValid()
    ? _c("div", { staticClass: "app-version" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("updates.app-version-note")) +
              " " +
              _vm._s(_vm.appVersion) +
              " "
          ),
        ]),
        _vm.checksEnabled
          ? _c("div", [
              !_vm.finished
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.error
                            ? "Error checking for updates."
                            : "Chcekcing for Updates..."
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.finished && _vm.isUpToDate
                ? _c("p", { staticClass: "up-to-date" }, [
                    _vm._v(" ✅ " + _vm._s(_vm.$t("updates.up-to-date")) + " "),
                  ])
                : _vm.finished && !_vm.veryOutOfDate
                ? _c("p", { staticClass: "update-availible" }, [
                    _vm._v(
                      " ⚠️" + _vm._s(_vm.$t("updates.out-of-date")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.latestVersion))]),
                  ])
                : _vm.finished && _vm.veryOutOfDate
                ? _c("p", { staticClass: "big-update-availible" }, [
                    _vm._v(
                      " ❗ " + _vm._s(_vm.$t("updates.out-of-date")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.latestVersion))]),
                    _c("span", { staticClass: "please-update" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("updates.unsupported-version-l1")) +
                          "."
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("updates.unsupported-version-l2")) +
                          " " +
                          _vm._s(_vm.latestVersion) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }