var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { staticClass: "options-label" }, [
      _vm._v(_vm._s(_vm.$t("settings.layout-label"))),
    ]),
    _c(
      "div",
      { staticClass: "display-options" },
      [
        _c("IconDeafault", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.layout-auto")),
              expression: "tooltip($t('settings.layout-auto'))",
            },
          ],
          class: `layout-icon ${_vm.layout === "auto" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateDisplayLayout("auto")
            },
          },
        }),
        _c("IconHorizontal", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.layout-horizontal")),
              expression: "tooltip($t('settings.layout-horizontal'))",
            },
          ],
          class: `layout-icon ${_vm.layout === "horizontal" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateDisplayLayout("horizontal")
            },
          },
        }),
        _c("IconVertical", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.layout-vertical")),
              expression: "tooltip($t('settings.layout-vertical'))",
            },
          ],
          class: `layout-icon ${_vm.layout === "vertical" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateDisplayLayout("vertical")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }