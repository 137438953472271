var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal edit-item",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "edit-item-inner" },
            [
              _c("h3", { staticClass: "title" }, [_vm._v("Edit Item")]),
              _c("p", { staticClass: "sub-title" }, [
                _vm._v(
                  "Editing " +
                    _vm._s(_vm.item.title) +
                    " (ID: " +
                    _vm._s(_vm.itemId) +
                    ")"
                ),
              ]),
              _vm.formData.length === 0
                ? _c("p", { staticClass: "warning-note" }, [
                    _vm._v(
                      " No data configured yet. Click an attribute in the list below to add the field to the form. "
                    ),
                  ])
                : _vm._e(),
              _vm._l(_vm.formData, function (row, index) {
                return _c(
                  "div",
                  { key: row.name, staticClass: "row" },
                  [
                    row.type === "text" || row.type === "number"
                      ? _c("Input", {
                          attrs: {
                            description: row.description,
                            label: row.title || row.name,
                            type: row.type,
                            layout: "horizontal",
                          },
                          model: {
                            value: _vm.formData[index].value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData[index], "value", $$v)
                            },
                            expression: "formData[index].value",
                          },
                        })
                      : row.type === "boolean"
                      ? _c("Radio", {
                          attrs: {
                            description: row.description,
                            label: row.title || row.name,
                            options: [..._vm.boolRadioOptions],
                            initialOption: _vm.boolToStr(
                              _vm.formData[index].value
                            ),
                          },
                          model: {
                            value: _vm.formData[index].value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData[index], "value", $$v)
                            },
                            expression: "formData[index].value",
                          },
                        })
                      : row.type === "select"
                      ? _c("Select", {
                          staticClass: "edit-item-select",
                          attrs: {
                            options: _vm.formData[index].enum,
                            description: row.description,
                            initialOption: _vm.formData[index].value,
                            label: row.title || row.name,
                          },
                          model: {
                            value: _vm.formData[index].value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData[index], "value", $$v)
                            },
                            expression: "formData[index].value",
                          },
                        })
                      : _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(row.name) +
                              " cannot currently be edited through the UI. "
                          ),
                        ]),
                    _c("BinIcon", {
                      on: { click: () => _vm.removeField(row.name) },
                    }),
                  ],
                  1
                )
              }),
              _vm.additionalFormData.length > 0
                ? _c("div", { staticClass: "add-more-inputs" }, [
                    _c("h4", [_vm._v("More Fields")]),
                    _c(
                      "div",
                      { staticClass: "more-fields" },
                      _vm._l(_vm.additionalFormData, function (row) {
                        return _c(
                          "span",
                          {
                            key: row.name,
                            staticClass: "add-field-tag",
                            on: { click: () => _vm.appendNewField(row.name) },
                          },
                          [
                            _c("AddIcon"),
                            _vm._v(" " + _vm._s(row.title || row.name) + " "),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _c("SaveCancelButtons", {
                attrs: {
                  saveClick: _vm.saveItem,
                  cancelClick: _vm.modalClosed,
                },
              }),
            ],
            2
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }