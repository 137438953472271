var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal edit-section",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "edit-section-inner" },
            [
              _c("h3", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `interactive-editor.edit-section.${
                          _vm.isAddNew ? "add" : "edit"
                        }-section-title`
                      )
                    ) +
                    " "
                ),
              ]),
              _c("FormSchema", {
                staticClass: "edit-section-form",
                attrs: { schema: _vm.customSchema, name: "editSectionForm" },
                model: {
                  value: _vm.sectionData,
                  callback: function ($$v) {
                    _vm.sectionData = $$v
                  },
                  expression: "sectionData",
                },
              }),
              _c("SaveCancelButtons", {
                attrs: {
                  saveClick: _vm.saveSection,
                  cancelClick: _vm.modalClosed,
                },
              }),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }