var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `item-wrapper wrap-size-${_vm.size} span-${_vm.makeColumnCount}` },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "longPress",
              rawName: "v-longPress",
              value: true,
              expression: "true",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltipOptions(),
              expression: "getTooltipOptions()",
            },
          ],
          class: `item ${_vm.makeClassList}`,
          style: _vm.customStyle,
          attrs: {
            href: _vm.item.url,
            target: _vm.anchorTarget,
            rel: `${_vm.item.rel || "noopener noreferrer"}`,
            tabindex: "0",
            id: `link-${_vm.item.id}`,
          },
          on: {
            click: _vm.itemClicked,
            "long-press": _vm.openContextMenu,
            contextmenu: function ($event) {
              $event.preventDefault()
            },
            mouseup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 2) return null
              return _vm.openContextMenu.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              class: `tile-title  ${!_vm.itemIcon ? "bounce no-icon" : ""}`,
              attrs: { id: `tile-${_vm.item.id}` },
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.item.title)),
              ]),
              _c("p", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.item.description)),
              ]),
            ]
          ),
          _c("Icon", {
            staticClass: "bounce",
            style: _vm.customStyles,
            attrs: {
              icon: _vm.itemIcon,
              url: _vm.item.url,
              size: _vm.size,
              color: _vm.item.color,
            },
          }),
          _c("ItemOpenMethodIcon", {
            staticClass: "opening-method-icon",
            attrs: {
              isSmall: !_vm.itemIcon || _vm.size === "small",
              openingMethod: _vm.accumulatedTarget,
              position: "bottom right",
              hotkey: _vm.item.hotkey,
            },
          }),
          _vm.enableStatusCheck
            ? _c("StatusIndicator", {
                staticClass: "status-indicator",
                attrs: {
                  statusSuccess: _vm.statusResponse
                    ? _vm.statusResponse.successStatus
                    : undefined,
                  statusText: _vm.statusResponse
                    ? _vm.statusResponse.message
                    : undefined,
                },
              })
            : _vm._e(),
          _vm.isEditMode
            ? _c("EditModeIcon", {
                staticClass: "edit-mode-item",
                on: {
                  click: function ($event) {
                    return _vm.openItemSettings()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("ContextMenu", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeContextMenu,
            expression: "closeContextMenu",
          },
        ],
        attrs: {
          show: _vm.contextMenuOpen && !_vm.isAddNew,
          posX: _vm.contextPos.posX,
          posY: _vm.contextPos.posY,
          id: `context-menu-${_vm.item.id}`,
        },
        on: {
          launchItem: _vm.launchItem,
          openItemSettings: _vm.openItemSettings,
          openMoveItemMenu: _vm.openMoveItemMenu,
          openDeleteItem: _vm.openDeleteItem,
        },
      }),
      _vm.isEditMode
        ? _c("MoveItemTo", { attrs: { itemId: _vm.item.id } })
        : _vm._e(),
      _vm.editMenuOpen
        ? _c("EditItem", {
            attrs: {
              itemId: _vm.item.id,
              isNew: _vm.isAddNew,
              parentSectionTitle: _vm.parentSectionTitle,
            },
            on: { closeEditMenu: _vm.closeEditMenu },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }