var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.icon
    ? _c(
        "div",
        { class: `item-icon wrapper-${_vm.size}` },
        [
          _vm.iconType === "font-awesome"
            ? _c("i", { class: `${_vm.icon} ${_vm.size}` })
            : _vm.iconType === "emoji"
            ? _c("i", { class: `emoji-icon ${_vm.size}` }, [
                _vm._v(_vm._s(_vm.getEmoji(_vm.iconPath))),
              ])
            : _vm.iconType === "mdi"
            ? _c("span", { class: `mdi ${_vm.icon} ${_vm.size}` })
            : _vm.iconType === "si" && !_vm.broken
            ? _c(
                "svg",
                {
                  class: `simple-icons ${_vm.size}`,
                  attrs: {
                    role: "img",
                    viewBox: "0 0 24 24",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [_c("path", { attrs: { d: _vm.getSimpleIcon(_vm.icon) } })]
              )
            : _vm.icon
            ? _c("img", {
                class: `tile-icon ${_vm.size} ${_vm.broken ? "broken" : ""}`,
                attrs: { src: _vm.iconPath },
                on: { error: _vm.imageNotFound },
              })
            : _vm._e(),
          _vm.broken
            ? _c("BrokenImage", { class: `missing-image ${_vm.size}` })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }