var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home", style: _vm.getBackgroundImage() },
    [
      _c("SettingsContainer", {
        ref: "filterComp",
        staticClass: "settings-outer",
        attrs: {
          displayLayout: _vm.layout,
          iconSize: _vm.itemSizeBound,
          externalThemes: _vm.getExternalCSSLinks(),
          modalOpen: _vm.modalOpen,
        },
        on: {
          "user-is-searchin": _vm.searching,
          "change-modal-visibility": _vm.updateModalVisibility,
        },
      }),
      _vm.singleSectionView
        ? _c(
            "div",
            [
              _c(
                "router-link",
                { staticClass: "back-to-all-link", attrs: { to: "/home" } },
                [_c("BackIcon"), _c("span", [_vm._v("Back to All")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.checkTheresData(_vm.sections) || _vm.isEditMode
        ? _c(
            "div",
            { class: _vm.computedClass },
            [
              _vm._l(_vm.filteredSections, function (section, index) {
                return [
                  _c("Section", {
                    key: index,
                    class:
                      _vm.searchValue && section.filteredItems.length === 0
                        ? "no-results"
                        : "",
                    attrs: {
                      index: index,
                      title: section.name,
                      icon: section.icon || undefined,
                      displayData: _vm.getDisplayData(section),
                      groupId: _vm.makeSectionId(section),
                      items: section.filteredItems,
                      widgets: section.widgets,
                      searchTerm: _vm.searchValue,
                      itemSize: _vm.itemSizeBound,
                      isWide:
                        !!_vm.singleSectionView ||
                        _vm.layoutOrientation === "horizontal",
                    },
                    on: {
                      itemClicked: function ($event) {
                        return _vm.finishedSearching()
                      },
                      "change-modal-visibility": _vm.updateModalVisibility,
                    },
                  }),
                ]
              }),
              _vm.isEditMode && !_vm.singleSectionView
                ? _c("AddNewSection")
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.checkIfResults(_vm.filteredSections) && !_vm.isEditMode
        ? _c("div", { staticClass: "no-data" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.searchValue
                    ? _vm.$t("home.no-results")
                    : _vm.$t("home.no-data")
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.isEditMode ? _c("EditModeSaveMenu") : _vm._e(),
      _c("ExportConfigMenu"),
      _vm.$store.state.isUsingLocalConfig ? _c("NotificationThing") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }