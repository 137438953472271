var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-switcher" }, [
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            { attrs: { to: `/home/${_vm.subPagePath}` } },
            [
              _c("IconHome"),
              _c("span", [_vm._v(_vm._s(_vm.$t("alternate-views.default")))]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            { attrs: { to: `/minimal/${_vm.subPagePath}` } },
            [
              _c("IconMinimalView"),
              _c("span", [_vm._v(_vm._s(_vm.$t("alternate-views.minimal")))]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            { attrs: { to: `/workspace/${_vm.subPagePath}` } },
            [
              _c("IconWorkspaceView"),
              _c("span", [_vm._v(_vm._s(_vm.$t("alternate-views.workspace")))]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }