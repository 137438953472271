var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: {
            content: _vm.statusText || _vm.otherStatusText,
            classes: ["status-tooltip", `tip-${_vm.color()}`],
            delay: { show: 0, hide: 150 },
          },
          expression:
            "{\n    content: statusText || otherStatusText,\n    classes: ['status-tooltip', `tip-${color()}`],\n    delay: { show: 0, hide: 150 }\n  }",
        },
      ],
      staticClass: "indicator",
    },
    [_c("div", { class: `dot dot-${_vm.color()}` }, [_vm._m(0)])]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }