var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("footer", [
        _vm.text
          ? _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
          : _c("span", [
              _c("a", { attrs: { href: _vm.defaultInfo.projectUrl } }, [
                _vm._v("Dashy"),
              ]),
              _vm._v(" is free & open source - licensed under "),
              _c("a", { attrs: { href: _vm.defaultInfo.licenseUrl } }, [
                _vm._v(_vm._s(_vm.defaultInfo.license)),
              ]),
              _vm._v(", © "),
              _c("a", { attrs: { href: _vm.defaultInfo.authorUrl } }, [
                _vm._v(_vm._s(_vm.defaultInfo.authorName)),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.defaultInfo.date) +
                  ". Get support on GitHub, at "
              ),
              _c("a", { attrs: { href: _vm.defaultInfo.repoUrl } }, [
                _vm._v(_vm._s(_vm.defaultInfo.repoName)),
              ]),
              _vm._v(". "),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }