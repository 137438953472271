var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-mode-bottom-banner" },
    [
      _vm.showEditMsg
        ? _c("div", { staticClass: "edit-banner-section intro-container" }, [
            _c("p", { staticClass: "section-sub-title edit-mode-intro l-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.edit-mode-subtitle")) +
                  " "
              ),
            ]),
            _c("p", { staticClass: "edit-mode-intro l-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("interactive-editor.menu.edit-mode-description")
                  ) +
                  " "
              ),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "edit-banner-section intro-container" },
            [_c("AccessError", { staticClass: "no-permission" })],
            1
          ),
      _c("div", { staticClass: "edit-banner-section empty-space" }),
      _c(
        "div",
        { staticClass: "edit-banner-section save-buttons-container" },
        [
          _c("p", { staticClass: "section-sub-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "interactive-editor.menu.config-save-methods-subheading"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.save-locally-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.save-locally-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.saveLocally,
                disallow: !_vm.permissions.allowSaveLocally,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.save-locally-btn")) +
                  " "
              ),
              _c("SaveLocallyIcon"),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.save-disk-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.save-disk-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.writeToDisk,
                disallow: !_vm.permissions.allowWriteToDisk,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.save-disk-btn")) +
                  " "
              ),
              _c("SaveToDiskIcon"),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.export-config-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.export-config-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.openExportConfigMenu,
                disallow: !_vm.permissions.allowViewConfig,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.export-config-btn")) +
                  " "
              ),
              _c("ExportIcon"),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.cancel-changes-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.cancel-changes-tooltip'))",
                },
              ],
              attrs: { click: _vm.reset },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.cancel-changes-btn")) +
                  " "
              ),
              _c("CancelIcon"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-banner-section edit-config-buttons-container" },
        [
          _c("p", { staticClass: "section-sub-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("interactive-editor.menu.edit-site-data-subheading")
                ) +
                " "
            ),
          ]),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.edit-page-info-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.edit-page-info-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.openEditPageInfo,
                disallow: !_vm.permissions.allowViewConfig,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.edit-page-info-btn")) +
                  " "
              ),
              _c("PageInfoIcon"),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.edit-app-config-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.edit-app-config-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.openEditAppConfig,
                disallow: !_vm.permissions.allowViewConfig,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("interactive-editor.menu.edit-app-config-btn")
                  ) +
                  " "
              ),
              _c("AppConfigIcon"),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(
                    _vm.$t("interactive-editor.menu.edit-pages-tooltip")
                  ),
                  expression:
                    "tooltip($t('interactive-editor.menu.edit-pages-tooltip'))",
                },
              ],
              attrs: {
                click: _vm.openEditMultiPages,
                disallow: !_vm.permissions.allowViewConfig,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("interactive-editor.menu.edit-pages-btn")) +
                  " "
              ),
              _c("MultiPagesIcon"),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditPageInfo"),
      _c("EditAppConfig"),
      _c("EditMultiPages"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }