var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShow
    ? _c("div", { staticClass: "critical-error-wrap" }, [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { title: "Close Warning" },
            on: { click: _vm.close },
          },
          [_vm._v("🗙")]
        ),
        _c("h3", [_vm._v(_vm._s(_vm.$t("critical-error.title")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t("critical-error.subtitle")))]),
        _c("h4", [_vm._v(_vm._s(_vm.$t("critical-error.sub-ensure-that")))]),
        _vm._m(0),
        _c("h4", [_vm._v(_vm._s(_vm.$t("critical-error.sub-error-details")))]),
        _c("pre", [_vm._v(_vm._s(this.$store.state.criticalError))]),
        _c("h4", [_vm._v(_vm._s(_vm.$t("critical-error.sub-next-steps")))]),
        _vm._m(1),
        _c(
          "button",
          { staticClass: "user-doesnt-care", on: { click: _vm.ignoreWarning } },
          [_vm._v(" " + _vm._s(_vm.$t("critical-error.ignore-button")) + " ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("The configuration file can be found at the specified location"),
      ]),
      _c("li", [
        _vm._v("There are no CORS rules preventing client-side access"),
      ]),
      _c("li", [_vm._v("The YAML is valid, parsable and matches the schema")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("Check the browser console for more details ("),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/Lissy93/dashy/blob/master/docs/troubleshooting.md#how-to-open-browser-console",
            },
          },
          [_vm._v("see how")]
        ),
        _vm._v(") "),
      ]),
      _c("li", [
        _vm._v("View the "),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/Lissy93/dashy/blob/master/docs/troubleshooting.md",
            },
          },
          [_vm._v("Troubleshooting Guide")]
        ),
        _vm._v(" and "),
        _c("a", { attrs: { href: "https://dashy.to/docs/" } }, [
          _vm._v("Docs"),
        ]),
      ]),
      _c("li", [
        _vm._v(
          " If you've verified the config is present, accessible and valid, and cannot find the solution in the troubleshooting, docs or GitHub issues, then "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/Lissy93/dashy/issues/new/choose",
            },
          },
          [_vm._v("open a ticket on GitHub")]
        ),
      ]),
      _c("li", [
        _vm._v(
          "Click 'Ignore Critical Errors' below to not show this warning again"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }