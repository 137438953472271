var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "55%",
        height: "80%",
        classes: "dashy-modal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "about-modal" },
        [
          _c("router-link", { staticClass: "title", attrs: { to: "/about" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("app-info.title")))]),
          ]),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.error-log")))]),
          _vm.errorLog
            ? _c("pre", { staticClass: "logs" }, [
                _c("code", [_vm._v(_vm._s(_vm.errorLog))]),
              ])
            : _c("p", [_vm._v(_vm._s(_vm.$t("app-info.no-errors")) + " :)")]),
          _c("hr"),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.help-support")))]),
          _vm._v(
            " " + _vm._s(_vm.$t("app-info.help-support-description")) + " "
          ),
          _c(
            "a",
            { attrs: { href: "https://github.com/Lissy93/dashy/discussions" } },
            [_vm._v(_vm._s(_vm.$t("app-info.help-support-discussions")))]
          ),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.support-dashy")))]),
          _vm._v(
            " " + _vm._s(_vm.$t("app-info.support-dashy-description")) + " "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/docs/contributing.md",
              },
            },
            [_vm._v(_vm._s(_vm.$t("app-info.support-dashy-link")))]
          ),
          _vm._v(". "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.report-bug")))]),
          _vm._v(" " + _vm._s(_vm.$t("app-info.report-bug-description")) + " "),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/issues/new/choose",
              },
            },
            [_vm._v(_vm._s(_vm.$t("app-info.report-bug-link")))]
          ),
          _vm._v(". "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.more-info")))]),
          _vm._v(" " + _vm._s(_vm.$t("app-info.source")) + ": "),
          _c("a", { attrs: { href: "https://github.com/lissy93/dashy" } }, [
            _vm._v("github.com/lissy93/dashy"),
          ]),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("app-info.documentation")) + ": "),
          _c("a", { attrs: { href: "https://dashy.to/docs" } }, [
            _vm._v("dashy.to/docs"),
          ]),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.privacy-and-security")))]),
          _vm._v(
            " " + _vm._s(_vm.$t("app-info.privacy-and-security-l1")) + " "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/docs/privacy.md",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("app-info.privacy-and-security-privacy-policy"))
              ),
            ]
          ),
          _vm._v("."),
          _c("br"),
          _vm._v(
            " " + _vm._s(_vm.$t("app-info.privacy-and-security-advice")) + " "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/docs/management.md",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("app-info.privacy-and-security-advice-link"))
              ),
            ]
          ),
          _vm._v("."),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(_vm.$t("app-info.privacy-and-security-security-issue")) +
              " "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/.github/SECURITY.md",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("app-info.privacy-and-security-security-policy"))
              ),
            ]
          ),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.license")))]),
          _vm._v(" " + _vm._s(_vm.$t("app-info.license-under")) + " "),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/LICENSE",
              },
            },
            [_vm._v("MIT X11")]
          ),
          _vm._v(". Copyright "),
          _c("a", { attrs: { href: "https://aliciasykes.com" } }, [
            _vm._v("Alicia Sykes"),
          ]),
          _vm._v(" © " + _vm._s(new Date().getFullYear()) + "."),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("app-info.licence-third-party")) + " "),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/.github/LEGAL.md",
              },
            },
            [_vm._v(_vm._s(_vm.$t("app-info.licence-third-party-link")))]
          ),
          _vm._v("."),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("app-info.list-contributors")) + " "),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Lissy93/dashy/blob/master/docs/credits.md",
              },
            },
            [_vm._v(_vm._s(_vm.$t("app-info.list-contributors-link")))]
          ),
          _vm._v(". "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("app-info.version")))]),
          _c("AppVersion", { staticClass: "app-version" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }