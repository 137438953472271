var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.subPageClassName,
      style: _vm.topLevelStyleModifications,
      attrs: { id: "dashy" },
    },
    [
      _vm.isEditMode ? _c("EditModeTopBanner") : _vm._e(),
      _vm.shouldShowSplash
        ? _c("LoadingScreen", { attrs: { isLoading: _vm.isLoading } })
        : _vm._e(),
      _c("Header", { attrs: { pageInfo: _vm.pageInfo } }),
      !_vm.isFetching ? _c("router-view") : _vm._e(),
      _vm.hasCriticalError ? _c("CriticalError") : _vm._e(),
      _vm.visibleComponents.footer && !_vm.isFetching
        ? _c("Footer", { attrs: { text: _vm.footerText } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }