var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal edit-app-config",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "edit-app-config-inner" },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("interactive-editor.menu.edit-app-config-btn"))
                ),
              ]),
              _c("div", { staticClass: "app-config-intro" }, [
                _c("p", { staticClass: "use-caution" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "interactive-editor.edit-app-config.warning-msg-title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "interactive-editor.edit-app-config.warning-msg-l1"
                      )
                    ) +
                    " " +
                    _vm._s(
                      _vm.$t(
                        "interactive-editor.edit-app-config.warning-msg-l2"
                      )
                    ) +
                    " "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://dashy.to/docs/configuring#appconfig-optional",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "interactive-editor.edit-app-config.warning-msg-docs"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "interactive-editor.edit-app-config.warning-msg-l3"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("SaveCancelButtons", {
                attrs: {
                  saveClick: _vm.saveToState,
                  cancelClick: _vm.cancelEditing,
                },
              }),
              _c("FormSchema", {
                staticClass: "app-config-form",
                attrs: {
                  schema: _vm.schema,
                  search: true,
                  name: "appConfigForm",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveToState.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.formData,
                  callback: function ($$v) {
                    _vm.formData = $$v
                  },
                  expression: "formData",
                },
              }),
              _c("SaveCancelButtons", {
                attrs: {
                  saveClick: _vm.saveToState,
                  cancelClick: _vm.cancelEditing,
                },
              }),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }