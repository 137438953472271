var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        { "is-open": _vm.isExpanded, "full-height": _vm.cutToHeight },
        `collapsable ${_vm.rowColSpanClass}`,
        _vm.sectionClassName,
      ],
      style: `${
        _vm.color ? "background: " + _vm.color : ""
      }; ${_vm.sanitizeCustomStyles(_vm.customStyles)};`,
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkboxState,
            expression: "checkboxState",
          },
        ],
        staticClass: "toggle",
        attrs: { id: _vm.sectionKey, type: "checkbox", tabIndex: "-1" },
        domProps: {
          checked: Array.isArray(_vm.checkboxState)
            ? _vm._i(_vm.checkboxState, null) > -1
            : _vm.checkboxState,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.checkboxState,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkboxState = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkboxState = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkboxState = $$c
            }
          },
        },
      }),
      _c(
        "label",
        {
          directives: [
            {
              name: "longPress",
              rawName: "v-longPress",
              value: 500,
              expression: "500",
            },
          ],
          staticClass: "lbl-toggle",
          attrs: { for: _vm.sectionKey, tabindex: "-1" },
          on: {
            mouseup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 2) return null
              return _vm.openContextMenu.apply(null, arguments)
            },
            contextmenu: function ($event) {
              $event.preventDefault()
            },
            "long-press": _vm.openContextMenu,
          },
        },
        [
          _vm.icon
            ? _c("Icon", {
                staticClass: "section-icon",
                attrs: { icon: _vm.icon, size: "small", url: _vm.title },
              })
            : _vm._e(),
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm.isEditMode
            ? _c("EditModeIcon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.editTooltip(),
                    expression: "editTooltip()",
                  },
                ],
                staticClass: "edit-mode-item",
                on: { click: _vm.openEditModal },
              })
            : _vm._e(),
          _c("OpenIcon", {
            staticClass: "edit-mode-item",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.openContextMenu.apply(null, arguments)
              },
              contextmenu: function ($event) {
                $event.preventDefault()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "collapsible-content" }, [
        _c("div", { staticClass: "content-inner" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }