var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.name,
        resizable: true,
        width: "80%",
        height: "80%",
        classes: "dashy-modal",
      },
      on: {
        closed: function ($event) {
          return _vm.modalClosed()
        },
      },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "top-right" },
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
          slot: "top-right",
        },
        [_vm._v("Close")]
      ),
      _c(
        "a",
        {
          staticClass: "close-button",
          attrs: { title: "Close" },
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
        },
        [_vm._v("x")]
      ),
      _vm.url
        ? _c("iframe", {
            staticClass: "frame",
            attrs: { src: _vm.url, allow: "fullscreen; clipboard-write" },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                return _vm.close.apply(null, arguments)
              },
            },
          })
        : _c("div", { staticClass: "no-url" }, [_vm._v("No URL Specified")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }