var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-container" },
    [
      _vm.label
        ? _c("label", { staticClass: "select-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c("v-select", {
        staticClass: "form-dropdown",
        attrs: {
          value: _vm.selectedOption,
          selectOnTab: true,
          options: _vm.options,
        },
        on: { input: _vm.updateValue },
      }),
      _vm.description
        ? _c("p", { staticClass: "select-description" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }