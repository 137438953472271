var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentVisible
    ? _c(
        "header",
        [
          _vm.titleVisible
            ? _c("PageTitle", {
                attrs: {
                  title: _vm.pageInfo.title,
                  description: _vm.pageInfo.description,
                  logo: _vm.pageInfo.logo,
                },
              })
            : _vm._e(),
          _vm.navVisible
            ? _c("Nav", {
                staticClass: "nav",
                attrs: { links: _vm.pageInfo.navLinks },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }