var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "page-titles",
      attrs: { to: "/", disabled: _vm.isEditMode },
    },
    [
      _vm.logo
        ? _c("img", { staticClass: "site-logo", attrs: { src: _vm.logo } })
        : _vm._e(),
      _c("div", { staticClass: "text" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _c("span", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
      ]),
      _vm.isEditMode
        ? _c("EditModeIcon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip(),
                expression: "tooltip()",
              },
            ],
            staticClass: "edit-icon",
            on: {
              click: function ($event) {
                return _vm.editTitle()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }