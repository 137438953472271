var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `theme-configurator-wrapper ${
        _vm.showingAllVars ? "showing-all" : ""
      }`,
    },
    [
      _c("h3", { staticClass: "configurator-title" }, [
        _vm._v(_vm._s(_vm.$t("theme-maker.title"))),
      ]),
      _c(
        "div",
        { staticClass: "color-row-container" },
        _vm._l(Object.keys(_vm.customColors), function (colorName) {
          return _c(
            "div",
            { key: colorName, staticClass: "color-row" },
            [
              _c(
                "label",
                {
                  staticClass: "color-name",
                  attrs: { for: `color-input-${colorName}` },
                },
                [_vm._v(" " + _vm._s(colorName.replaceAll("-", " ")) + " ")]
              ),
              _vm.isColor(colorName, _vm.customColors[colorName])
                ? _c(
                    "v-swatches",
                    {
                      attrs: {
                        "show-fallback": "",
                        "fallback-input-type": "color",
                        "popover-x": "left",
                        swatches: _vm.swatches,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setVariable(
                            colorName,
                            _vm.customColors[colorName]
                          )
                        },
                      },
                      model: {
                        value: _vm.customColors[colorName],
                        callback: function ($$v) {
                          _vm.$set(_vm.customColors, colorName, $$v)
                        },
                        expression: "customColors[colorName]",
                      },
                    },
                    [
                      _c("input", {
                        staticClass: "swatch-input form__input__element",
                        style: _vm.makeSwatchStyles(colorName),
                        attrs: {
                          slot: "trigger",
                          id: `color-input-${colorName}`,
                          readonly: "",
                        },
                        domProps: { value: _vm.customColors[colorName] },
                        slot: "trigger",
                      }),
                    ]
                  )
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customColors[colorName],
                        expression: "customColors[colorName]",
                      },
                    ],
                    class: `misc-input ${
                      _vm.isTextual(colorName, _vm.customColors[colorName])
                        ? "long-input"
                        : ""
                    }`,
                    attrs: { id: `color-input-${colorName}` },
                    domProps: { value: _vm.customColors[colorName] },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customColors,
                            colorName,
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.setVariable(
                            colorName,
                            _vm.customColors[colorName]
                          )
                        },
                      ],
                    },
                  }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "p",
        {
          staticClass: "action-text-btn show-all-vars-btn",
          on: { click: _vm.showFontVariables },
        },
        [_vm._v(" " + _vm._s(_vm.$t("theme-maker.change-fonts-button")) + " ")]
      ),
      _c(
        "p",
        {
          staticClass: "action-text-btn show-all-vars-btn",
          on: { click: _vm.findAllVariableNames },
        },
        [_vm._v(" " + _vm._s(_vm.$t("theme-maker.show-all-button")) + " ")]
      ),
      _c(
        "p",
        {
          staticClass: "action-text-btn",
          on: { click: _vm.exportToClipboard },
        },
        [_vm._v(" " + _vm._s(_vm.$t("theme-maker.export-button")) + " ")]
      ),
      _c(
        "p",
        { staticClass: "action-text-btn", on: { click: _vm.resetAndSave } },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("theme-maker.reset-button")) +
              " '" +
              _vm._s(_vm.themeToEdit) +
              "' "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "action-buttons" },
        [
          _c(
            "Button",
            { attrs: { click: _vm.saveChanges } },
            [
              _c("SaveIcon"),
              _vm._v(" " + _vm._s(_vm.$t("theme-maker.save-button")) + " "),
            ],
            1
          ),
          _c(
            "Button",
            { attrs: { click: _vm.resetUnsavedColors } },
            [
              _c("CancelIcon"),
              _vm._v(" " + _vm._s(_vm.$t("theme-maker.cancel-button")) + " "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }