var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Collapsable",
    {
      ref: _vm.sectionRef,
      attrs: {
        title: _vm.title,
        icon: _vm.icon,
        uniqueKey: _vm.groupId,
        collapsed: _vm.displayData.collapsed,
        cols: _vm.displayData.cols,
        rows: _vm.displayData.rows,
        color: _vm.displayData.color,
        customStyles: _vm.displayData.customStyles,
        cutToHeight: _vm.displayData.cutToHeight,
        id: _vm.sectionRef,
      },
      on: {
        openEditSection: _vm.openEditSection,
        openContextMenu: _vm.openContextMenu,
      },
    },
    [
      _vm.isEmpty
        ? _c("div", { staticClass: "no-items" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.no-items-section")) + " "),
          ])
        : _vm._e(),
      _vm.hasItems
        ? _c(
            "div",
            {
              class: `there-are-items ${
                _vm.isGridLayout ? "item-group-grid" : ""
              } inner-size-${_vm.itemSize}`,
              style: _vm.gridStyle,
              attrs: { id: `section-${_vm.groupId}` },
            },
            [
              _vm._l(_vm.sortedItems, function (item) {
                return [
                  item.subItems
                    ? _c("SubItemGroup", {
                        key: item.id,
                        attrs: {
                          itemId: item.id,
                          title: item.title,
                          subItems: item.subItems,
                        },
                        on: { triggerModal: _vm.triggerModal },
                      })
                    : _c("Item", {
                        key: item.id,
                        attrs: {
                          item: item,
                          itemSize: _vm.itemSize,
                          parentSectionTitle: _vm.title,
                          isAddNew: false,
                          sectionWidth: _vm.sectionWidth,
                          sectionDisplayData: _vm.displayData,
                        },
                        on: {
                          itemClicked: function ($event) {
                            return _vm.$emit("itemClicked")
                          },
                          triggerModal: _vm.triggerModal,
                        },
                      }),
                ]
              }),
              _vm.isEditMode
                ? _c("Item", {
                    key: "add-new",
                    staticClass: "add-new-item",
                    attrs: {
                      item: {
                        icon: ":heavy_plus_sign:",
                        title: "Add New Item",
                        description: "Click to add new item",
                        id: "add-new",
                      },
                      isAddNew: true,
                      parentSectionTitle: _vm.title,
                      sectionWidth: _vm.sectionWidth,
                      itemSize: _vm.itemSize,
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.hasWidgets
        ? _c(
            "div",
            { class: `widget-list ${_vm.isWide ? "wide" : ""}` },
            _vm._l(_vm.widgets, function (widget, widgetIndx) {
              return _c("WidgetBase", {
                key: widgetIndx,
                attrs: { widget: widget, index: _vm.index },
                on: { navigateToSection: _vm.navigateToSection },
              })
            }),
            1
          )
        : _vm._e(),
      _c("IframeModal", {
        ref: `iframeModal-${_vm.groupId}`,
        attrs: { name: `iframeModal-${_vm.groupId}` },
        on: {
          closed: function ($event) {
            return _vm.$emit("itemClicked")
          },
        },
      }),
      _vm.editMenuOpen
        ? _c("EditSection", {
            attrs: { sectionIndex: _vm.index, isAddNew: false },
            on: { closeEditSection: _vm.closeEditSection },
          })
        : _vm._e(),
      _c("ContextMenu", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeContextMenu,
            expression: "closeContextMenu",
          },
        ],
        attrs: {
          show: _vm.contextMenuOpen,
          posX: _vm.contextPos.posX,
          posY: _vm.contextPos.posY,
          id: `context-menu-${_vm.groupId}`,
        },
        on: {
          openEditSection: _vm.openEditSection,
          navigateToSection: _vm.navigateToSection,
          expandCollapseSection: _vm.expandCollapseSection,
          removeSection: _vm.removeSection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }