var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "css-editor-outer" }, [
    _c(
      "div",
      { staticClass: "style-section css-wrapper" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.$t("config.custom-css.title")))]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.customCss,
              expression: "customCss",
            },
          ],
          staticClass: "css-editor",
          domProps: { value: _vm.customCss },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.customCss = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "Button",
          { staticClass: "save-button", attrs: { click: _vm.save } },
          [_vm._v(_vm._s(_vm.$t("config.css-save-btn")))]
        ),
        _c("p", { staticClass: "quick-note" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("config.css-note-label")) + ":")]),
          _vm._v(
            " " +
              _vm._s(_vm.$t("config.css-note-l1")) +
              " " +
              _vm._s(_vm.$t("config.css-note-l2")) +
              " " +
              _vm._s(_vm.$t("config.css-note-l3")) +
              " "
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "style-section base-theme-wrapper" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.$t("config.custom-css.base-theme")))]),
        _c("ThemeSelector", { attrs: { hidePallete: true } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "style-section" },
      [
        _c("CustomThemeMaker", {
          staticClass: "color-config",
          attrs: { themeToEdit: _vm.currentTheme },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }