var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      class: _vm.minimalSearch ? "minimal" : "normal",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.searchSubmitted.apply(null, arguments)
        },
      },
    },
    [
      _c("label", { attrs: { for: "filter-tiles" } }, [
        _vm._v(_vm._s(_vm.$t("search.search-label"))),
      ]),
      _c("div", { staticClass: "search-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          ref: "filter",
          attrs: {
            id: "filter-tiles",
            placeholder: _vm.$t("search.search-placeholder"),
          },
          domProps: { value: _vm.input },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.input = $event.target.value
              },
              _vm.userIsTypingSomething,
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.clearFilterInput.apply(null, arguments)
            },
          },
        }),
        !_vm.searchPrefs.disableWebSearch && _vm.input.length > 0
          ? _c("p", { staticClass: "web-search-note" }, [
              _vm._v(" " + _vm._s(_vm.$t("search.enter-to-search-web")) + " "),
            ])
          : _vm._e(),
      ]),
      _vm.input.length > 0
        ? _c(
            "i",
            {
              staticClass: "clear-search",
              attrs: { title: _vm.$t("search.clear-search-tooltip") },
              on: { click: _vm.clearFilterInput },
            },
            [_vm._v("x")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }