var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "save-cancel-btn-container" },
    [
      _c(
        "Button",
        { staticClass: "save-app-config-btn", attrs: { click: _vm.saveClick } },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("interactive-editor.menu.save-stage-btn")) + " "
          ),
          _c("SaveIcon"),
        ],
        1
      ),
      _c(
        "Button",
        {
          staticClass: "save-app-config-btn",
          attrs: { click: _vm.cancelClick },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("interactive-editor.menu.cancel-stage-btn")) +
              " "
          ),
          _c("CancelIcon"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }