var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal edit-multi-pages",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "edit-multi-pages-inner" },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("interactive-editor.menu.edit-page-info-btn"))
                ),
              ]),
              _c(
                "FormSchema",
                {
                  staticClass: "multi-page-form",
                  attrs: { schema: _vm.customSchema, name: "multiPageForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveToState.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.formData,
                    callback: function ($$v) {
                      _vm.formData = $$v
                    },
                    expression: "formData",
                  },
                },
                [
                  _c("SaveCancelButtons", {
                    attrs: {
                      saveClick: _vm.saveToState,
                      cancelClick: _vm.cancelEditing,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }