var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { staticClass: "user-type-note" }, [
      _vm._v(_vm._s(_vm.makeUserGreeting())),
    ]),
    _c(
      "div",
      { staticClass: "display-options" },
      [
        _vm.userType == _vm.userStateEnum.loggedIn
          ? _c("IconLogout", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(_vm.$t("settings.sign-out-tooltip")),
                  expression: "tooltip($t('settings.sign-out-tooltip'))",
                },
              ],
              staticClass: "layout-icon",
              attrs: { tabindex: "-2" },
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            })
          : _vm._e(),
        _vm.userType == _vm.userStateEnum.guestAccess
          ? _c("IconLogout", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(_vm.$t("settings.sign-in-tooltip")),
                  expression: "tooltip($t('settings.sign-in-tooltip'))",
                },
              ],
              staticClass: "layout-icon",
              attrs: { tabindex: "-2" },
              on: {
                click: function ($event) {
                  return _vm.goToLogin()
                },
              },
            })
          : _vm._e(),
        _vm.userType == _vm.userStateEnum.keycloakEnabled
          ? _c("IconLogout", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(_vm.$t("settings.sign-out-tooltip")),
                  expression: "tooltip($t('settings.sign-out-tooltip'))",
                },
              ],
              staticClass: "layout-icon",
              attrs: { tabindex: "-2" },
              on: {
                click: function ($event) {
                  return _vm.keycloakLogout()
                },
              },
            })
          : _vm._e(),
        _vm.userType == _vm.userStateEnum.oidcEnabled
          ? _c("IconLogout", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip(_vm.$t("settings.sign-out-tooltip")),
                  expression: "tooltip($t('settings.sign-out-tooltip'))",
                },
              ],
              staticClass: "layout-icon",
              attrs: { tabindex: "-2" },
              on: {
                click: function ($event) {
                  return _vm.oidcLogout()
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }