var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.show && !_vm.isMenuDisabled
      ? _c(
          "div",
          {
            staticClass: "context-menu",
            style: _vm.posX && _vm.posY ? _vm.calcPosition() : "",
          },
          [
            _c("ul", { staticClass: "menu-section" }, [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.openSection()
                    },
                  },
                },
                [
                  _c("SameTabOpenIcon"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("context-menus.section.open-section"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.openEditSectionMenu } },
                [
                  _c("EditIcon"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("context-menus.section.edit-section"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.expandCollapseSection } },
                [
                  _c("ExpandCollapseIcon"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("context-menus.section.expand-collapse"))
                    ),
                  ]),
                ],
                1
              ),
              _vm.isEditMode
                ? _c(
                    "li",
                    { on: { click: _vm.removeSection } },
                    [
                      _c("BinIcon"),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("context-menus.section.remove-section"))
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }