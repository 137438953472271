var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sub-items-group",
      style: `--sub-item-col-count: ${_vm.columnCount}`,
    },
    [
      _vm.title
        ? _c("p", { staticClass: "sub-item-group-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm._l(_vm.subItems, function (subItem, subIndex) {
        return _c("SubItem", {
          key: subIndex,
          attrs: { id: `${_vm.itemId}-sub-${subIndex}`, item: subItem },
          on: { triggerModal: _vm.triggerModal },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }