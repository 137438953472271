var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.hoverText,
          expression: "hoverText",
        },
      ],
      class: _vm.disallow ? "disallowed" : "",
      attrs: {
        type: _vm.type || "button",
        disabled: _vm.disabled || _vm.disallow,
        title: _vm.tooltip,
      },
      on: {
        click: function ($event) {
          _vm.click ? _vm.click() : () => null
        },
      },
    },
    [_vm._t("default"), _vm._t("text"), _vm._t("icon")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }