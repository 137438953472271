var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Tabs",
    {
      ref: "tabView",
      class: { hideTabs: !_vm.enableConfig },
      attrs: { navAuto: true, name: "Add Item" },
    },
    [
      _c(
        "TabItem",
        { staticClass: "main-tab", attrs: { name: _vm.$t("config.main-tab") } },
        [
          _c("div", { staticClass: "main-options-container" }, [
            _c(
              "div",
              { staticClass: "config-buttons" },
              [
                _c("h2", [_vm._v(_vm._s(_vm.$t("config.heading")))]),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: {
                      disallow: !_vm.enableConfig,
                      click: _vm.openExportConfigModal,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("config.download-config-button")) +
                        " "
                    ),
                    _c("DownloadIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: {
                      disallow: !_vm.enableConfig,
                      click: _vm.openEditConfigTab,
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("config.edit-config-button")) + " "
                    ),
                    _c("EditIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: { click: _vm.openLanguageSwitchModal },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("config.change-language-button")) +
                        " "
                    ),
                    _c("LanguageIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: {
                      disallow: !_vm.enableConfig,
                      click: _vm.openEditCssTab,
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("config.edit-css-button")) + " "
                    ),
                    _c("CustomCssIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: {
                      disallow: !_vm.enableConfig,
                      click: _vm.openCloudSyncTab,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.backupId
                            ? _vm.$t("config.edit-cloud-sync-button")
                            : _vm.$t("config.cloud-sync-button")
                        ) +
                        " "
                    ),
                    _c("CloudIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: {
                      disallow: !_vm.enableConfig,
                      click: _vm.openRebuildAppModal,
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("config.rebuild-app-button")) + " "
                    ),
                    _c("RebuildIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: { click: _vm.resetLocalSettings },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("config.reset-settings-button")) + " "
                    ),
                    _c("DeleteIcon", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    staticClass: "config-button",
                    attrs: { click: _vm.openAboutModal },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("config.app-info-button")) + " "
                    ),
                    _c("IconAbout", { staticClass: "button-icon" }),
                  ],
                  1
                ),
                _c("p", { staticClass: "language" }, [
                  _vm._v(_vm._s(_vm.getLanguage())),
                ]),
                _c("p", { staticClass: "config-location" }, [
                  _vm._v(" Using config from "),
                  _c("a", { attrs: { href: _vm.configPath } }, [
                    _vm._v(_vm._s(_vm.configPath)),
                  ]),
                ]),
                _c("AppVersion"),
              ],
              1
            ),
            !_vm.enableConfig
              ? _c("p", { staticClass: "config-disabled-note" }, [
                  _vm._v(_vm._s(_vm.$t("config.disabled-note"))),
                ])
              : _vm._e(),
            _c(
              "p",
              {
                staticClass: "small-screen-note",
                staticStyle: { display: "none" },
              },
              [_vm._v(_vm._s(_vm.$t("config.small-screen-note")))]
            ),
            _c(
              "div",
              {
                staticClass: "config-note",
                on: { click: _vm.openExportConfigModal },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("config.backup-note")))])]
            ),
          ]),
          _c("RebuildApp"),
        ],
        1
      ),
      _vm.enableConfig
        ? _c(
            "TabItem",
            { attrs: { name: _vm.$t("config.edit-config-tab") } },
            [_c("JsonEditor")],
            1
          )
        : _vm._e(),
      _vm.enableConfig
        ? _c(
            "TabItem",
            { attrs: { name: _vm.$t("cloud-sync.title") } },
            [_c("CloudBackupRestore")],
            1
          )
        : _vm._e(),
      _vm.enableConfig
        ? _c(
            "TabItem",
            { attrs: { name: _vm.$t("config.custom-css-tab") } },
            [_c("CustomCssEditor")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }