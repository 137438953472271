var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sub-item-wrapper" },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "longPress",
              rawName: "v-longPress",
              value: true,
              expression: "true",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.subItemTooltip,
              expression: "subItemTooltip",
            },
          ],
          staticClass: "sub-item-link item",
          attrs: {
            href: _vm.hyperLinkHref,
            target: _vm.anchorTarget,
            rel: "noopener noreferrer",
            tabindex: "0",
            id: `link-${_vm.id}`,
          },
          on: {
            click: _vm.itemClicked,
            contextmenu: function ($event) {
              $event.preventDefault()
            },
            "long-press": _vm.openContextMenu,
            mouseup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 2) return null
              return _vm.openContextMenu.apply(null, arguments)
            },
          },
        },
        [
          _c("Icon", {
            staticClass: "sub-icon-img bounce",
            style: _vm.customStyles,
            attrs: { icon: _vm.item.icon, url: _vm.item.url, size: "small" },
          }),
        ],
        1
      ),
      _c("ContextMenu", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeContextMenu,
            expression: "closeContextMenu",
          },
        ],
        attrs: {
          show: _vm.contextMenuOpen && !_vm.isAddNew,
          posX: _vm.contextPos.posX,
          posY: _vm.contextPos.posY,
          id: `context-menu-${_vm.id}`,
          disableEdit: true,
        },
        on: { launchItem: _vm.launchItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }