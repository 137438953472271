var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal edit-page-info",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "edit-page-info-inner" },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("interactive-editor.menu.edit-page-info-btn"))
                ),
              ]),
              _c(
                "FormSchema",
                {
                  staticClass: "page-info-form",
                  attrs: { schema: _vm.schema, name: "pageInfoForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveToState.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.formData,
                    callback: function ($$v) {
                      _vm.formData = $$v
                    },
                    expression: "formData",
                  },
                },
                [
                  _c(
                    "Button",
                    { attrs: { type: "submit" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("interactive-editor.menu.save-stage-btn")
                          ) +
                          " "
                      ),
                      _c("SaveIcon"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }