var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.allowViewConfig
    ? _c(
        "div",
        { staticClass: "json-editor-outer" },
        [
          _c("v-jsoneditor", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.jsonData,
              callback: function ($$v) {
                _vm.jsonData = $$v
              },
              expression: "jsonData",
            },
          }),
          _c("Radio", {
            staticClass: "save-options",
            attrs: {
              label: _vm.$t("config-editor.save-location-label"),
              options: _vm.saveOptions,
              initialOption: _vm.initialSaveMode,
              disabled: !_vm.allowWriteToDisk || !_vm.allowSaveLocally,
            },
            model: {
              value: _vm.saveMode,
              callback: function ($$v) {
                _vm.saveMode = $$v
              },
              expression: "saveMode",
            },
          }),
          _c(
            "div",
            { class: `btn-container ${!_vm.isValid ? "err" : ""}` },
            [
              _c(
                "Button",
                {
                  attrs: {
                    click: _vm.save,
                    disallow: !_vm.allowWriteToDisk && !_vm.allowSaveLocally,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("config-editor.save-button")) + " "
                  ),
                ]
              ),
              _c("Button", { attrs: { click: _vm.startPreview } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("config-editor.preview-button")) + " "
                ),
              ]),
            ],
            1
          ),
          _c("p", { staticClass: "errors" }, [
            _c(
              "ul",
              [
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("li", { key: index, class: `type-${error.type}` }, [
                    _vm._v(" " + _vm._s(error.msg) + " "),
                  ])
                }),
                _vm.errorMessages.length < 1
                  ? _c("li", { staticClass: "type-valid" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("config-editor.valid-label")) + " "
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm.saveSuccess !== undefined
            ? _c(
                "p",
                {
                  class: `response-output status-${
                    _vm.saveSuccess ? "success" : "fail"
                  }`,
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.saveSuccess
                          ? _vm.$t("config-editor.status-success-msg")
                          : _vm.$t("config-editor.status-fail-msg")
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          !_vm.allowWriteToDisk
            ? _c("p", { staticClass: "no-permission-note" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("config-editor.not-admin-note")) + " "
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "response-output" }, [
            _vm._v(_vm._s(_vm.responseText)),
          ]),
          _vm.saveSuccess
            ? _c("p", { staticClass: "response-output" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("config-editor.success-note-l1")) +
                    " " +
                    _vm._s(_vm.$t("config-editor.success-note-l2")) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "note" }, [
            _vm._v(_vm._s(_vm.$t("config.backup-note"))),
          ]),
        ],
        1
      )
    : _c("AccessError")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }