var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.allLinks && _vm.allLinks.length > 0
    ? _c(
        "div",
        { staticClass: "nav-outer" },
        [
          _c("IconBurger", {
            class: `burger ${!_vm.navVisible ? "visible" : ""}`,
            on: {
              click: function ($event) {
                _vm.navVisible = !_vm.navVisible
              },
            },
          }),
          _vm.navVisible
            ? _c(
                "nav",
                { attrs: { id: "nav" } },
                [
                  _vm._l(_vm.allLinks, function (link, index) {
                    return [
                      !_vm.isUrl(link.path)
                        ? _c(
                            "router-link",
                            {
                              key: index,
                              staticClass: "nav-item",
                              attrs: { to: link.path },
                            },
                            [_vm._v(_vm._s(link.title) + " ")]
                          )
                        : _c(
                            "a",
                            {
                              key: index,
                              staticClass: "nav-item",
                              attrs: {
                                href: link.path,
                                target: _vm.determineTarget(link),
                                rel: "noopener noreferrer",
                              },
                            },
                            [_vm._v(_vm._s(link.title) + " ")]
                          ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }