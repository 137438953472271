var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeThemeConfigurator,
          expression: "closeThemeConfigurator",
        },
      ],
      staticClass: "theme-selector-section",
    },
    [
      _c(
        "div",
        [
          _c("span", { staticClass: "theme-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.theme-label"))),
          ]),
          _c("v-select", {
            staticClass: "theme-dropdown",
            attrs: {
              options: _vm.themeNames,
              value: _vm.$store.getters.theme,
              tabindex: -2,
            },
            on: { input: _vm.themeChangedInUI },
            model: {
              value: _vm.selectedTheme,
              callback: function ($$v) {
                _vm.selectedTheme = $$v
              },
              expression: "selectedTheme",
            },
          }),
        ],
        1
      ),
      !_vm.hidePallete
        ? _c("IconPalette", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("theme-maker.title"),
                expression: "$t('theme-maker.title')",
              },
            ],
            staticClass: "color-button",
            on: { click: _vm.openThemeConfigurator },
          })
        : _vm._e(),
      _vm.themeConfiguratorOpen
        ? _c("CustomThemeMaker", {
            attrs: { themeToEdit: _vm.selectedTheme },
            on: {
              closeThemeConfigurator: function ($event) {
                return _vm.closeThemeConfigurator()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }