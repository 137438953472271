var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "60%",
        classes: "dashy-modal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "rebuild-app-container" },
        [
          _c("h3", { staticClass: "rebuild-app-title" }, [
            _vm._v(_vm._s(_vm.$t("app-rebuild.title"))),
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("app-rebuild.rebuild-note-l1")) +
                " " +
                _vm._s(_vm.$t("app-rebuild.rebuild-note-l2"))
            ),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("app-rebuild.rebuild-note-l3")) + " "),
          ]),
          _c("Button", {
            attrs: {
              click: _vm.startBuild,
              disabled: _vm.loading || !_vm.allowRebuild,
              disallow: !_vm.allowRebuild,
            },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.loading
                            ? _vm.$t("app-rebuild.rebuilding-status-1")
                            : _vm.$t("app-rebuild.rebuild-button")
                        ) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "icon",
                fn: function () {
                  return [_c("RebuildIcon")]
                },
                proxy: true,
              },
            ]),
          }),
          !_vm.allowRebuild
            ? _c("div", [
                _c("p", { staticClass: "disallow-rebuild-msg" }, [
                  _vm._v(_vm._s(_vm.$t("app-rebuild.error-permission"))),
                ]),
              ])
            : _vm._e(),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "loader-info" },
                [
                  _c("LoadingAnimation", { staticClass: "loader" }),
                  _c("p", { staticClass: "loading-message" }, [
                    _vm._v(
                      _vm._s(_vm.$t("app-rebuild.rebuilding-status-2")) + "..."
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.success !== undefined
            ? _c(
                "div",
                { staticClass: "rebuild-response" },
                [
                  _vm.success
                    ? _c("p", { staticClass: "response-status success" }, [
                        _vm._v(
                          " ✅ " +
                            _vm._s(_vm.$t("app-rebuild.success-msg")) +
                            " "
                        ),
                      ])
                    : _c("p", { staticClass: "response-status failure" }, [
                        _vm._v(
                          " ❌ " + _vm._s(_vm.$t("app-rebuild.fail-msg")) + " "
                        ),
                      ]),
                  _c("pre", { staticClass: "output" }, [
                    _c("code", [_vm._v(_vm._s(_vm.output || _vm.error))]),
                  ]),
                  _c("p", { staticClass: "rebuild-message" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                  _vm.success
                    ? _c("p", { staticClass: "rebuild-message" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("app-rebuild.reload-note")) + " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.success
                    ? _c("Button", {
                        attrs: { click: _vm.refreshPage },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("app-rebuild.reload-button"))
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("ReloadIcon")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3658341317
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }