var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-container" }, [
    _vm.label
      ? _c("label", { staticClass: "radio-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "radio-wrapper" },
      _vm._l(_vm.options, function (radio) {
        return _c(
          "div",
          {
            key: radio.value,
            class: `radio-option ${_vm.disabled ? "wrap-disabled" : ""}`,
          },
          [
            _c(
              "label",
              {
                staticClass: "option-label",
                attrs: { for: `id-${radio.value}` },
              },
              [_vm._v(_vm._s(radio.label))]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedRadio,
                  expression: "selectedRadio",
                },
              ],
              staticClass: "radio-input",
              attrs: {
                type: "radio",
                id: `id-${radio.value}`,
                name: _vm.makeGroupName,
                disabled: _vm.disabled || radio.disabled,
              },
              domProps: {
                value: radio.value,
                checked: _vm._q(_vm.selectedRadio, radio.value),
              },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
                change: function ($event) {
                  _vm.selectedRadio = radio.value
                },
              },
            }),
          ]
        )
      }),
      0
    ),
    _vm.description
      ? _c("p", { staticClass: "radio-description" }, [
          _vm._v(_vm._s(_vm.description)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }