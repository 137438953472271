var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { class: { "settings-hidden": !_vm.settingsVisible } },
    [
      _vm.searchVisible
        ? _c("SearchBar", {
            ref: "SearchBar",
            on: { "user-is-searchin": _vm.userIsTypingSomething },
          })
        : _vm._e(),
      _c("div", { staticClass: "options-outer" }, [
        _c(
          "div",
          { class: `options-container ${!_vm.settingsVisible ? "hide" : ""}` },
          [
            _c("ThemeSelector"),
            _c("LayoutSelector", {
              attrs: { displayLayout: _vm.$store.getters.layout },
            }),
            _c("ItemSizeSelector", { attrs: { iconSize: _vm.iconSize } }),
            _c("ConfigLauncher"),
            _vm.userState !== 0
              ? _c("AuthButtons", { attrs: { userType: _vm.userState } })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            class: `show-hide-container ${
              _vm.settingsVisible ? "hide-btn" : "show-btn"
            }`,
          },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: `${
                      _vm.settingsVisible ? "Hide" : "Open"
                    } Settings Menu`,
                    expression:
                      "`${settingsVisible? 'Hide' : 'Open'} Settings Menu`",
                  },
                ],
                attrs: { tabindex: "-2" },
                on: {
                  click: function ($event) {
                    return _vm.toggleSettingsVisibility()
                  },
                },
              },
              [_vm.settingsVisible ? _c("IconClose") : _c("IconOpen")],
              1
            ),
          ]
        ),
      ]),
      _c("AppInfoModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }