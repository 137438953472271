var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _vm.isLoading
      ? _c(
          "div",
          {
            class: _vm.c,
            attrs: { id: "loading" },
            on: {
              click: function ($event) {
                _vm.c = "hide"
              },
            },
          },
          [
            _c("h2", [_vm._v("Dashy")]),
            _c("div", { staticClass: "inner-container" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("splash-screen.loading")))]),
              _c("span", { staticClass: "dots-cont" }, [
                _c("span", { staticClass: "dot dot-1" }),
                _c("span", { staticClass: "dot dot-2" }),
                _c("span", { staticClass: "dot dot-3" }),
                _c("span", { staticClass: "dot dot-4" }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }