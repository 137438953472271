import { render, staticRenderFns } from "./SaveCancelButtons.vue?vue&type=template&id=f74e022c&scoped=true"
import script from "./SaveCancelButtons.vue?vue&type=script&lang=js"
export * from "./SaveCancelButtons.vue?vue&type=script&lang=js"
import style0 from "./SaveCancelButtons.vue?vue&type=style&index=0&id=f74e022c&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f74e022c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f74e022c')) {
      api.createRecord('f74e022c', component.options)
    } else {
      api.reload('f74e022c', component.options)
    }
    module.hot.accept("./SaveCancelButtons.vue?vue&type=template&id=f74e022c&scoped=true", function () {
      api.rerender('f74e022c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/InteractiveEditor/SaveCancelButtons.vue"
export default component.exports