var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "50%",
        height: "80%",
        classes: "dashy-modal export-modal",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "export-config-inner" },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("interactive-editor.export.export-title"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "download-button-container" },
                [
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltip(
                            _vm.$t(
                              "interactive-editor.export.copy-clipboard-tooltip"
                            )
                          ),
                          expression:
                            "tooltip($t('interactive-editor.export.copy-clipboard-tooltip'))",
                        },
                      ],
                      attrs: { click: _vm.copyConfigToClipboard },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "interactive-editor.export.copy-clipboard-btn"
                            )
                          ) +
                          " "
                      ),
                      _c("CopyConfigIcon"),
                    ],
                    1
                  ),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltip(
                            _vm.$t(
                              "interactive-editor.export.download-file-tooltip"
                            )
                          ),
                          expression:
                            "tooltip($t('interactive-editor.export.download-file-tooltip'))",
                        },
                      ],
                      attrs: { click: _vm.downloadConfig },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "interactive-editor.export.download-file-btn"
                            )
                          ) +
                          " "
                      ),
                      _c("DownloadConfigIcon"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "config-path-info" }, [
                _c("h3", [_vm._v("Config Location")]),
                _c("p", [
                  _vm._v(" The base config file you are currently using is "),
                  _c("a", { attrs: { href: _vm.configPath } }, [
                    _vm._v(_vm._s(_vm.configPath)),
                  ]),
                ]),
              ]),
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("interactive-editor.export.view-title"))),
              ]),
              _c("tree-view", {
                staticClass: "config-tree-view",
                attrs: { data: _vm.config },
              }),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }