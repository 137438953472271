var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.show && !_vm.isMenuDisabled
      ? _c(
          "div",
          {
            staticClass: "context-menu",
            style:
              _vm.posX && _vm.posY
                ? `top:${_vm.posY}px;left:${_vm.posX}px;`
                : "",
          },
          [
            _c("ul", { staticClass: "menu-section" }, [
              _c("li", { staticClass: "section-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("context-menus.item.open-section-title")) +
                    " "
                ),
              ]),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("sametab")
                    },
                  },
                },
                [
                  _c("SameTabOpenIcon"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("context-menus.item.sametab"))),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("newtab")
                    },
                  },
                },
                [
                  _c("NewTabOpenIcon"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("context-menus.item.newtab"))),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("modal")
                    },
                  },
                },
                [
                  _c("IframeOpenIcon"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("context-menus.item.modal"))),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("workspace")
                    },
                  },
                },
                [
                  _c("WorkspaceOpenIcon"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("context-menus.item.workspace"))),
                  ]),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("clipboard")
                    },
                  },
                },
                [
                  _c("ClipboardOpenIcon"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("context-menus.item.clipboard"))),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "ul",
              {
                staticClass: "menu-section",
                class: { disabled: !_vm.isEditAllowed },
              },
              [
                _c("li", { staticClass: "section-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("context-menus.item.options-section-title")
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openSettings()
                      },
                    },
                  },
                  [
                    _c("EditIcon"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("context-menus.item.edit-item"))),
                    ]),
                  ],
                  1
                ),
                _vm.isEditMode
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openMoveMenu()
                          },
                        },
                      },
                      [
                        _c("MoveIcon"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("context-menus.item.move-item"))
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isEditMode
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openDeleteItem()
                          },
                        },
                      },
                      [
                        _c("BinIcon"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("context-menus.item.remove-item"))
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }