var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `widget-base ${_vm.loading ? "is-loading" : ""}` },
    [
      !_vm.hideControls && !_vm.loading
        ? _c(
            "Button",
            {
              staticClass: "action-btn update-btn",
              attrs: { click: _vm.update },
            },
            [_c("UpdateIcon")],
            1
          )
        : _vm._e(),
      !_vm.hideControls && !_vm.error && !_vm.loading
        ? _c(
            "Button",
            {
              staticClass: "action-btn open-btn",
              attrs: { click: _vm.fullScreenWidget },
            },
            [_c("OpenIcon")],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [
              _vm.loading
                ? _c("LoadingAnimation", { staticClass: "loader" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c("div", { staticClass: "widget-error" }, [
            _c("p", { staticClass: "error-msg" }, [
              _vm._v("An error occurred, see the logs for more info."),
            ]),
            _c("p", { staticClass: "error-output" }, [
              _vm._v(_vm._s(_vm.errorMsg)),
            ]),
            _c("p", { staticClass: "retry-link", on: { click: _vm.update } }, [
              _vm._v("Retry"),
            ]),
          ])
        : _vm._e(),
      _vm.widgetOptions.label
        ? _c("div", { staticClass: "widget-label" }, [
            _vm._v(_vm._s(_vm.widgetOptions.label)),
          ])
        : _vm._e(),
      _c(
        "div",
        { class: `widget-wrap ${_vm.error ? "has-error" : ""}` },
        [
          _c(_vm.component, {
            ref: _vm.widgetRef,
            tag: "component",
            attrs: { options: _vm.widgetOptions },
            on: { loading: _vm.setLoaderState, error: _vm.handleError },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }