var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.modalName,
        resizable: true,
        width: "40%",
        height: "40%",
        classes: "dashy-modal",
      },
      on: { closed: _vm.close },
    },
    [
      _vm.allowViewConfig
        ? _c(
            "div",
            { staticClass: "move-menu-inner" },
            [
              _c("h3", { staticClass: "move-title" }, [
                _vm._v("Move or Copy Item"),
              ]),
              _c("p", { staticClass: "item-id" }, [
                _vm._v("Editing " + _vm._s(_vm.itemId)),
              ]),
              _c("Radio", {
                attrs: {
                  options: _vm.operationRadioOptions,
                  label: "Operation Type",
                  initialOption: _vm.operation,
                },
                model: {
                  value: _vm.operation,
                  callback: function ($$v) {
                    _vm.operation = $$v
                  },
                  expression: "operation",
                },
              }),
              _c("Select", {
                attrs: {
                  options: _vm.sectionList,
                  initialOption: _vm.selectedSection,
                  label: "Destination",
                },
                model: {
                  value: _vm.selectedSection,
                  callback: function ($$v) {
                    _vm.selectedSection = $$v
                  },
                  expression: "selectedSection",
                },
              }),
              _c("Radio", {
                attrs: {
                  options: _vm.appendToRadioOptions,
                  label: "Append To",
                  initialOption: _vm.appendTo,
                },
                model: {
                  value: _vm.appendTo,
                  callback: function ($$v) {
                    _vm.appendTo = $$v
                  },
                  expression: "appendTo",
                },
              }),
              _c("SaveCancelButtons", {
                attrs: { saveClick: _vm.save, cancelClick: _vm.close },
              }),
            ],
            1
          )
        : _c("AccessError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }