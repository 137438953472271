var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: `input-container ${_vm.layout}` }, [
    _vm.label
      ? _c("label", { staticClass: "input-label", attrs: { for: "name" } }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c("input", {
      staticClass: "input-field",
      attrs: {
        type: _vm.type,
        name: _vm.name,
        id: _vm.name,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.updateValue($event.target.value)
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          _vm.onEnter ? _vm.onEnter() : () => {}
        },
      },
    }),
    _vm.description
      ? _c("p", { staticClass: "input-description" }, [
          _vm._v(" " + _vm._s(_vm.description) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }