var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "language-switcher" },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("language-switcher.title"))),
      ]),
      _c("p", { staticClass: "intro" }, [
        _vm._v(_vm._s(_vm.$t("language-switcher.dropdown-label")) + ":"),
      ]),
      _c("v-select", {
        staticClass: "language-dropdown",
        attrs: {
          selectOnTab: true,
          options: _vm.languageList,
          label: "friendlyName",
          input: _vm.applyLanguageLocally(),
        },
        model: {
          value: _vm.language,
          callback: function ($$v) {
            _vm.language = $$v
          },
          expression: "language",
        },
      }),
      _c(
        "Button",
        {
          staticClass: "save-button",
          attrs: { click: _vm.saveLanguage, disallow: !_vm.language },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("language-switcher.save-button")) + " "),
          _c("SaveConfigIcon"),
        ],
        1
      ),
      _vm.language
        ? _c("p", { staticClass: "current-lang" }, [
            _vm._v(
              " 🌐 " +
                _vm._s(_vm.language.flag) +
                " " +
                _vm._s(_vm.language.name) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.$i18n.availableLocales.length <= 1
        ? _c("p", { staticClass: "sad-times" }, [
            _vm._v(
              " There are not currently any additional languages supported, but stay tuned as more are on their way! "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }