var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    !_vm.shouldHide
      ? _c("div", { staticClass: "kb-sc-info" }, [
          _c("h5", [_vm._v(_vm._s(_vm.popupContent.title))]),
          _c(
            "div",
            {
              staticClass: "close",
              attrs: { title: "Hide forever [Esc]" },
              on: {
                click: function ($event) {
                  return _vm.hideWelcomeHelper()
                },
              },
            },
            [_vm._v("x")]
          ),
          _c("p", { attrs: { title: _vm.popupContent.hoverText } }, [
            _vm._v(_vm._s(_vm.popupContent.message)),
          ]),
          _c("p", { attrs: { title: _vm.popupContent.hoverText } }, [
            _vm._v(_vm._s(_vm.popupContent.messageContinued)),
          ]),
          _c("div", { staticClass: "action-buttons" }, [
            _c("button", { on: { click: _vm.exportConfig } }, [
              _vm._v("Export Local Config"),
            ]),
            _c("button", { on: { click: _vm.saveConfig } }, [
              _vm._v("Save Changes to Disk"),
            ]),
            _c("button", { on: { click: _vm.resetLocalConfig } }, [
              _vm._v("Reset Local Changes"),
            ]),
            _c("button", { on: { click: _vm.hideWelcomeHelper } }, [
              _vm._v("Dismiss this Notification"),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }