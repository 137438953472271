var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-section" },
    [
      _vm.isEditMode
        ? _c(
            "div",
            {
              staticClass: "add-new-section",
              on: {
                click: function ($event) {
                  return _vm.openAddNewSectionMenu()
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "➕ " +
                    _vm._s(
                      _vm.$t(
                        "interactive-editor.edit-section.add-section-title"
                      )
                    )
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isEditMode && _vm.addNewSectionOpen
        ? _c("EditSectionMenu", {
            attrs: { isAddNew: true },
            on: { closeEditSection: _vm.closeEditSection },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }