var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeViewSwitcher,
          expression: "closeViewSwitcher",
        },
      ],
      staticClass: "config-options",
    },
    [
      _c("span", { staticClass: "config-label" }, [
        _vm._v(_vm._s(_vm.$t("settings.config-launcher-label"))),
      ]),
      _c(
        "div",
        { staticClass: "config-buttons" },
        [
          _c("IconSpanner", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip(_vm.$t("settings.config-launcher-tooltip")),
                expression: "tooltip($t('settings.config-launcher-tooltip'))",
              },
            ],
            attrs: { tabindex: "-2" },
            on: {
              click: function ($event) {
                return _vm.showEditor()
              },
            },
          }),
          _c("IconInteractiveEditor", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip(_vm.enterEditModeTooltip),
                expression: "tooltip(enterEditModeTooltip)",
              },
            ],
            class: _vm.isEditMode || !_vm.isEditAllowed ? "disabled" : "",
            attrs: { tabindex: "-2" },
            on: {
              click: function ($event) {
                return _vm.startInteractiveEditor()
              },
            },
          }),
          _c("IconViewMode", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip(
                  _vm.$t("alternate-views.alternate-view-heading")
                ),
                expression:
                  "tooltip($t('alternate-views.alternate-view-heading'))",
              },
            ],
            attrs: { tabindex: "-2" },
            on: {
              click: function ($event) {
                return _vm.openChangeViewMenu()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: _vm.modalNames.CONF_EDITOR,
            resizable: true,
            width: "60%",
            height: "85%",
            classes: "dashy-modal",
          },
          on: { closed: _vm.editorClosed },
        },
        [_c("ConfigContainer", { attrs: { config: _vm.combineConfig() } })],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: _vm.modalNames.LANG_SWITCHER,
            classes: "dashy-modal",
            resizable: true,
            width: "35%",
            height: "60%",
          },
        },
        [_c("LanguageSwitcher")],
        1
      ),
      _vm.viewSwitcherOpen ? _c("ViewSwitcher") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }