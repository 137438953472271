var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cloud-backup-restore-wrapper" }, [
    _c("div", { staticClass: "section intro" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("cloud-sync.title")))]),
      _c("p", { staticClass: "intro" }, [
        _vm._v(" " + _vm._s(_vm.$t("cloud-sync.intro-l1")) + " "),
        _c("br"),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("cloud-sync.intro-l2")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("cloud-sync.intro-l3")) + " "),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/Lissy93/dashy/blob/master/docs/backup-restore.md",
            },
          },
          [_vm._v(_vm._s(_vm.$t("cloud-sync.intro-docs")))]
        ),
        _vm._v(". "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "section backup-section" },
      [
        _vm.backupId
          ? _c("h3", [_vm._v(_vm._s(_vm.$t("cloud-sync.backup-title-setup")))])
          : _c("h3", [_vm._v(_vm._s(_vm.$t("cloud-sync.backup-title-setup")))]),
        _c("Input", {
          attrs: {
            name: "backup-password",
            label: _vm.backupId
              ? _vm.$t("cloud-sync.password-label-update")
              : _vm.$t("cloud-sync.password-label-setup"),
            layout: "vertical",
            type: "password",
          },
          model: {
            value: _vm.backupPassword,
            callback: function ($$v) {
              _vm.backupPassword = $$v
            },
            expression: "backupPassword",
          },
        }),
        _c(
          "Button",
          { attrs: { click: _vm.checkPass } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.backupId
                    ? _vm.$t("cloud-sync.backup-button-update")
                    : _vm.$t("cloud-sync.backup-button-setup")
                ) +
                " "
            ),
            _c("IconBackup"),
          ],
          1
        ),
        _vm.backupId
          ? _c("div", { staticClass: "results-view" }, [
              _c("span", { staticClass: "backup-id-label" }, [
                _vm._v(_vm._s(_vm.$t("cloud-sync.backup-id-label")) + ": "),
              ]),
              _c("pre", { staticClass: "backup-id-value" }, [
                _vm._v(_vm._s(_vm.backupId)),
              ]),
              _c("span", { staticClass: "backup-id-note" }, [
                _vm._v(_vm._s(_vm.$t("cloud-sync.backup-id-note"))),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "section restore-section" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.$t("cloud-sync.restore-title")))]),
        _c("Input", {
          attrs: {
            name: "restore-code",
            label: _vm.$t("cloud-sync.restore-id-label"),
          },
          model: {
            value: _vm.restoreCode,
            callback: function ($$v) {
              _vm.restoreCode = $$v
            },
            expression: "restoreCode",
          },
        }),
        _c("Input", {
          attrs: {
            name: "restore-password",
            label: _vm.$t("cloud-sync.restore-password-label"),
            type: "password",
          },
          model: {
            value: _vm.restorePassword,
            callback: function ($$v) {
              _vm.restorePassword = $$v
            },
            expression: "restorePassword",
          },
        }),
        _c(
          "Button",
          { attrs: { click: _vm.restoreBackup } },
          [
            _vm._v(" " + _vm._s(_vm.$t("cloud-sync.restore-button")) + " "),
            _c("IconRestore"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }