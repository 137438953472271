var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.makeClass(_vm.position, _vm.isSmall, _vm.isTransparent) },
      [
        _vm.openingMethod === "newtab"
          ? _c("NewTabOpenIcon")
          : _vm.openingMethod === "sametab"
          ? _c("SameTabOpenIcon")
          : _vm.openingMethod === "modal"
          ? _c("IframeOpenIcon")
          : _vm.openingMethod === "workspace"
          ? _c("WorkspaceOpenIcon")
          : _vm.openingMethod === "parent"
          ? _c("ParentOpenIcon")
          : _vm.openingMethod === "top"
          ? _c("TopOpenIcon")
          : _vm.openingMethod === "clipboard"
          ? _c("ClipboardOpenIcon")
          : _c("UnknownIcon"),
      ],
      1
    ),
    _vm.hotkey
      ? _c(
          "div",
          {
            class: `hotkey-denominator ${_vm.makeClass(
              _vm.position,
              _vm.isSmall,
              _vm.isTransparent
            )}`,
          },
          [_vm._v(" " + _vm._s(_vm.hotkey) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }