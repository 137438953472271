var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { staticClass: "options-label" }, [
      _vm._v(_vm._s(_vm.$t("settings.item-size-label"))),
    ]),
    _c(
      "div",
      { staticClass: "display-options" },
      [
        _c("IconSmall", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.item-size-small")),
              expression: "tooltip($t('settings.item-size-small'))",
            },
          ],
          class: `layout-icon ${_vm.iconSize === "small" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateIconSize("small")
            },
          },
        }),
        _c("IconMedium", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.item-size-medium")),
              expression: "tooltip($t('settings.item-size-medium'))",
            },
          ],
          class: `layout-icon ${_vm.iconSize === "medium" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateIconSize("medium")
            },
          },
        }),
        _c("IconLarge", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(_vm.$t("settings.item-size-large")),
              expression: "tooltip($t('settings.item-size-large'))",
            },
          ],
          class: `layout-icon ${_vm.iconSize === "large" ? "selected" : ""}`,
          attrs: { tabindex: "-2" },
          on: {
            click: function ($event) {
              return _vm.updateIconSize("large")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }